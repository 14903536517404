// Import Swiper React components
import { Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Slideshow styles
import "./Slideshow.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
// Import images
const images = require.context("./assets", false);
const imageList = images.keys().map((image) => images(image));

export const Slideshow = () => {
  return (
    <Swiper
      modules={[Pagination, Autoplay]}
      spaceBetween={30}
      slidesPerView={1}
      loop={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{ dynamicBullets: true, clickable: true }}
    >
      {imageList.map((image, index) => (
        <SwiperSlide>
          <div className="slide-wrapper">
            <img
              key={index}
              src={image}
              className="slide-image"
              alt={"slide-image" + index}
            ></img>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
