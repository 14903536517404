import React from "react";
import "./SocialFollow.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

const SocialFollow = () => {
  return (
    <div className="social-container">
      <a
        href="mailto:sweetleedesserts@gmail.com"
        target="_blank"
        rel="noopener noreferrer"
        className="email social"
      >
        <FontAwesomeIcon icon={faEnvelope} size="1x"/>
      </a>
      <a
        href="https://www.instagram.com/sweetleedesserts/"
        target="_blank"
        rel="noopener noreferrer"
        className="instagram social"
      >
        <FontAwesomeIcon icon={faInstagram} size="1x" />
      </a>
    </div>
  );
};

export default SocialFollow;
