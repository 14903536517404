import './App.css';
import {Route, Routes} from "react-router-dom";
import NavigationBar from './components/NavigationBar';
import Footer from "./components/Footer";
import {Home, About, Order, Cart} from './components/pages';
import { OrderContextProvider } from './context/OrderContext';


function App() {
  return (
    <div className="App">
      <OrderContextProvider>
      <NavigationBar/>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/about" element={<About />}/>
        <Route path="/order" element={<Order />}/>
        <Route path="/cart" element={<Cart />}/>
      </Routes>
      <Footer />
      </OrderContextProvider>
    </div>
  );
}

export default App;
