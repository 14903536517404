import { createContext, useState } from "react";
import { PRODUCTS } from "../products";

export const OrderContext = createContext(null);
const getDefaultCart = () => {
  let cart = {};
  for (let i = 0; i < PRODUCTS.length; i++) {
    cart[i] = 0;
  }
  return cart;
};

export const OrderContextProvider = (props) => {
  const [cartItems, setCartItems] = useState(getDefaultCart());
  const [itemCount, setItemsCount] = useState(0);

  const getTotalCartAmount = () => {
    let totalAmount = 0;
    for (const item in cartItems) {
      if (cartItems[item] > 0) {
        let itemInfo = PRODUCTS.find((product) => product.id === Number(item));
        totalAmount += cartItems[item] * itemInfo.price;
      }
    }
    return totalAmount;
  };

  const addToCart = (itemId) => {
    setCartItems((prev) => (
      { ...prev, [itemId]: prev[itemId] < 99 ? prev[itemId] + 1 : prev[itemId]}
      ));
    setItemsCount(itemCount + 1);
  };

  const removeFromCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] - 1 }));
    setItemsCount(itemCount - 1);
  };

  const checkout = () => {
    setCartItems(getDefaultCart());
    setItemsCount(0);
  };

  const contextValue = { cartItems, getTotalCartAmount, addToCart, removeFromCart, checkout, itemCount };

  return (
    <OrderContext.Provider value={contextValue}>
      {props.children}
    </OrderContext.Provider>
  );
};
