import React, { useContext } from "react";
import { OrderContext } from "../context/OrderContext";
import "./Product.css";

const Product = (props) => {
  const { id, productName, productSubDesc, productSize, price, productImage } = props.data;
  const { addToCart, cartItems } = useContext(OrderContext);
  const cartItemAmount = cartItems[id];
  return (
    <div className="product">
      <img className="product-image" src={productImage} alt={productName} />
      <div className="description">{productName} - {productSize}</div>
      <div className="sub-description">{productSubDesc}</div>
      <div className="product-price">
        <b>${price}</b>
      </div>
      <button className="add-button" onClick={() => addToCart(id)}>
        Add To Order
        {cartItemAmount > 0 && <> <span style={{color: '#db3286'}}>({cartItemAmount})</span></>}
      </button>
    </div>
  );
};

export default Product;
