import { Link, NavLink } from "react-router-dom";
import React, { useState } from "react";
import { Squash as Hamburger } from "hamburger-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import "./NavigationBar.css";
function NavigationBar() {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <nav>
      <Link
        to="/"
        onClick={() => {
          setMenuOpen(false);
        }}
        className="title"
      >
        SweetLee Desserts
      </Link>

      <div className="menu">
        <Hamburger rounded toggled={menuOpen} toggle={setMenuOpen} />
      </div>
      <ul className={menuOpen ? "open" : ""}>
        <li className="link">
          <NavLink
            to="/about"
            onClick={() => {
              setMenuOpen(false);
            }}
          >
            About
          </NavLink>
        </li>
        <li className="link">
          <NavLink
            to="/order"
            onClick={() => {
              setMenuOpen(false);
            }}
          >
            Order
          </NavLink>
        </li>
        <li className="link">
          <NavLink
            to="/cart"
            onClick={() => {
              setMenuOpen(false);
            }}
          >
            <FontAwesomeIcon icon={faCartShopping} size="1x" />
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default NavigationBar;
