import React, { useContext } from "react";
import "./Cart.css";
import { PRODUCTS } from "../../products";
import { OrderContext } from "../../context/OrderContext";
import { CartItem } from "../CartItem";
import { useNavigate } from "react-router-dom";

export const Cart = () => {
  const { cartItems, getTotalCartAmount, checkout } = useContext(OrderContext);
  const totalAmount = getTotalCartAmount();
  const navigate = useNavigate();

  const orderToString = () => {
    const orderItems = PRODUCTS.filter(
      (product) => cartItems[product.id] !== 0
    );
    let orderString = "";
    orderItems.forEach((item) => {
      orderString +=
        cartItems[item.id] +
        "x " +
        "(id:" +
        item.id +
        ")" +
        item.productName +
        " - " +
        item.productSubDesc +
        " = $" +
        item.price * cartItems[item.id] +
        "\n";
    });
    orderString += "-------------------------------\nTotal: $" + totalAmount;
    return orderString;
  };

  return (
    <div className="cart">
      <header>
        <div className="title-wrapper">
          <h1>Cart</h1>
        </div>
      </header>

      <div className="cart-wrapper">
        <div className="order-items">
          {totalAmount > 0 ? (
            PRODUCTS.filter((product) => cartItems[product.id] !== 0).map(
              (product) => {
                return <CartItem data={product} />;
              }
            )
          ) : (
            <div className="cart">
              <h1 className="cart-empty"> Your Order Cart is Empty</h1>
              <button
                className="cart-option-button"
                onClick={() => navigate("/order")}
              >
                Place an Order
              </button>
            </div>
          )}
        </div>
        {totalAmount > 0 && (
          <div>
            <div className="cart-total-wrapper">
              <h3>Order Total: ${totalAmount.toFixed(2)}</h3>
            </div>
            <div className="cart-options-wrapper">
              <button
                className="cart-option-button"
                onClick={() => navigate("/order")}
              >
                Continue Ordering
              </button>
              <button className="cart-option-button" onClick={() => checkout()}>
                Clear Order
              </button>
            </div>

            <div className="order-instructions-wrapper">
              <div className="order-instructions">Please fill out the form below to submit your order.<div id='line-break'></div>A confirmation email will be sent within 24 hours to discuss payment/transaction options.</div>
            </div>
            <div className="order-form">
              <form name="contact" method="POST">
                <input type="hidden" name="form-name" value="contact" />
                <p>
                  <label className="form-label" htmlFor="name">
                    Name
                  </label>{" "}
                  <br />
                  <input
                    className="order-form-input in-name"
                    type="text"
                    name="name"
                    required
                  />
                </p>
                <p>
                  <label className="form-label" htmlFor="email">
                    Email
                  </label>{" "}
                  <br />
                  <input
                    className="order-form-input in-email"
                    type="email"
                    name="email"
                    required
                  />
                </p>
                <p>
                  <label className="form-label" htmlFor="phone">
                    Phone
                  </label>{" "}
                  <br />
                  <input
                    className="order-form-input in-phone"
                    type="phone"
                    name="phone"
                    required
                  />
                </p>
                <p>
                  <input
                    type="hidden"
                    name="order"
                    value={orderToString()}
                  ></input>
                </p>
                <p>
                  <label className="form-label" htmlFor="message">
                    Message
                  </label>{" "}
                  <br />
                  <textarea
                    className="order-form-input in-message"
                    name="message"
                    placeholder="(optional)"
                  ></textarea>
                </p>
                <p>
                  <button
                    type="submit"
                    value="Send Order"
                    className="cart-option-button"
                  >
                    Send Order
                  </button>
                </p>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
