import product1 from "./assets/macs_var.jpeg";
import product2 from "./assets/macs_choc_straw.jpeg";
import product3 from "./assets/macs_hon_lav.jpeg";
import product4 from "./assets/macs_rasp_cham.jpeg";


export const PRODUCTS = [
  {
    id: 0,
    productName: "Macarons",
    productSubDesc: "Valentine's Day Assorted (2 ea. flavor)",
    productSize: "6pk",
    price: 15.00,
    productImage: product1,
  },
  {
    id: 1,
    productName: "Macarons",
    productSubDesc: "Chocolate Covered Strawberry Ganache",
    productSize: "6pk",
    price: 15.00,
    productImage: product2,
  },
  {
    id: 2,
    productName: "Macarons",
    productSubDesc: "Honey Lavendar Buttercream",
    productSize: "6pk",
    price: 15.00,
    productImage: product3,
  },
  {
    id: 3,
    productName: "Macarons",
    productSubDesc: "Raspberry Champagne Jam",
    productSize: "6pk",
    price: 15.00,
    productImage: product4,
  },
];
