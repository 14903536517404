import React from "react";
import "./About.css";
import ph1 from "./assets/sweetlee_logo.jpg";
import ph2 from "./assets/sweetlee_about_img3.png";

export const About = () => {
  return (
    <div className="about">
      <header>
        <div className="title-wrapper">
          <h1>About SweetLee</h1>
        </div>
      </header>

      <div className="about-grid">
        <div className="about-cell">
          <div className="img-wrapper">
            <img
              src={ph1}
              className="about-image"
              alt="Placeholder"
              width="600"
              height="750"
            ></img>
          </div>
        </div>
        <div className="about-cell">
          <div className="text-wrapper">
            <h2 className="about-name">Liana Olenchak</h2>
            <br />
            <p className="about-body">
              SweetLee Desserts is a small business run by Liana Olenchak in
              Metuchen, New Jersey. Liana found a passion for baking at a young
              age, when she would always join her mom in the kitchen and help
              with any type of baking project. Liana took this passion even
              further and attended the Restaurant School at Walnut Hill College
              in Philadelphia, PA where she graduated with an Associates Degree
              in Baking and Pastry. Over the past 15 years, Liana has worked in
              several bakeries and created decadent desserts for family and
              friends. In her free time, Liana loves trying new recipes (sweet
              and savory) and looking into new baking trends! Thank you for
              being part of this sweet adventure!
            </p>
          </div>
        </div>

        <div className="about-cell">
          <div className="text-wrapper">
            <h2 className="about-name">My Mission</h2>
            <br />
            <p className="about-body">
              Here at SweetLee Desserts, all baked goods are made fresh, with
              love. With a commitment to quality, creativity, and personalized
              service, SweetLee Desserts strives to make every celebration, big
              or small, a little sweeter. This small home-based bakery is a
              labor of love, where each treat is crafted with detailed care and
              passion. SweetLee Desserts aims to be more than just a source of
              delicious confections; we aspire to be a cherished part of your
              special moments, bringing smiles and sweet memories to your
              doorstep. Join SweetLee Desserts on this delightful journey,
              where every bite is a taste of homemade happiness.
            </p>
          </div>
        </div>
        <div className="about-cell">
          <div className="img-wrapper">
            <img
              src={ph2}
              className="about-image"
              alt="Placeholder"
              width="600"
              height="750"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};
