import React, { useContext } from "react";
import { OrderContext } from "../context/OrderContext";
import "./CartItem.css";

export const CartItem = (props) => {
  const { id, productName, productSubDesc, productSize, price } = props.data;
  const { cartItems, addToCart, removeFromCart } = useContext(OrderContext);
  return (

      <div className="order-list-item">
        <div className="order-list-title">{productName} - {productSize} : {productSubDesc}</div>
        <div className="amount-calc">
          <div className="price"><b>${(price*cartItems[id]).toFixed(2)}</b></div>
          <button className="incr-button" onClick={() => removeFromCart(id)}> - </button>
          <div className="amount"><b>{cartItems[id]}</b></div>
          <button className="incr-button" onClick={() => addToCart(id)}> + </button>
        </div>
      </div>

    // <div className="cart-item">
    //   <img src={productImage} alt={productName} />
    //   <div className="description">
    //     <p>
    //       {" "}
    //       <b>{productName}</b>
    //     </p>
    //     <p>${price}</p>
    //   </div>
    //   <div className="count-handler">
    //     <button onClick={() => removeFromCart(id)}> - </button>
    //     <div className="amount">{cartItems[id]}</div>
    //     <button onClick={() => addToCart(id)}> + </button>
    //   </div>
    // </div>
  );
};
