import "./Footer.css";
import SocialFollow from "./SocialFollow";
const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer>
      <div className="contact-info">
      {`SweetLee Desserts LLC - ${year}`} &#x2022; <SocialFollow />
      </div>
    </footer>
  );
};

export default Footer;
