import React from "react";
import "./Home.css";
import {Slideshow} from "../Slideshow";

export const Home = () => {
  return (
    <div className="home">
      <Slideshow/>
      <div className="home-title-wrapper">
        <h1 className="home-title">SweetLee Desserts</h1>
        <h3 className="home-title">by Liana Olenchak</h3>
      </div>

      <div className="home-news-wrapper">
        <div className="home-news">
            <h1 className="home-title">News</h1>
            <br />
            <p>Give your special someone a sweet treat this Valentine's Day!<div id='line-break'></div>Go to the Order page and place your order now for 6 pack macarons.</p>
        </div>
      </div>
    </div>
  );
};
