import React, { useContext } from "react";
import "./Order.css";
import { PRODUCTS } from "../../products";
import Product from "../Product";
import { useNavigate } from "react-router-dom";
import { OrderContext } from "../../context/OrderContext";

export const Order = () => {
  const { itemCount} = useContext(OrderContext);
  const navigate = useNavigate();
  return (
    <div className="order">
      <header>
        <div className="title-wrapper">
          <h1>Order</h1>
        </div>
      </header>
      <div className="products">
        {PRODUCTS.map((product) => (
          <Product data={product} />
        ))}
      </div>
      <button className="to-cart-button" onClick={() => navigate("/cart")}>
        Go to Cart 
        {itemCount > 0 && <> <span style={{color: '#db3286'}}>({itemCount})</span></>}
      </button>
    </div>
  );
};
